import type { LogtoConfig } from '@logto/react';
import LogtoClient from '@logto/browser';

const noteConfig: LogtoConfig = {
	endpoint: 'https://account.ihook.center',
	appId: 'fe7p4rqfkd08jftzkivn9',
	resources: ['https://localhost:3123'],
};

const apartmentConfig: LogtoConfig = {
	endpoint: 'https://account.ihook.center',
	appId: 'fe7p4rqfkd08jftzkivn9',
	resources: ['https://localhost:3123'],
};

/**
 * logto 如果分成两个应用？这里是一个应用就是一个设置
 * 显然现在这种合体应用深藏在url里面，无法分离。
 * 分离有两个问题点：
 * 1. 登录模块也需要包在 logto provider 里面
 * 2. 所有的配置需要配置两份 很麻烦 应该建立一整套机制 从域名到ip、 域名证书、 nginx分发、应用部署 都是更多的工作量
 */
export default function LogtoContainer({ children, app }: any) {
	const config: LogtoConfig = app === 'note' ? noteConfig : apartmentConfig;

	return children;
	// return <LogtoProvider config={config}>{children}</LogtoProvider>;
}

/**
 * 准备弃用他们提供的react sdk了。
 * 理想的场景是，到了token需要刷新的时候，token会进行刷新。
 * 现在的实现方式，trpc的config变化会比auth变化慢。
 * trpc接入auth
 */

export const logtoClient = new LogtoClient(noteConfig);
