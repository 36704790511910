import { logtoClient } from "@/LogtoContainer";
import { isNil } from "lodash-es";
import { apiResource } from './apiResource'

export const tryFreshToken = async (app: string) => {
    const tempToken = await logtoClient.getAccessToken(apiResource);

    if (isNil(tempToken)) {
        // token 400 为空 直接退出了
        // 说明没有登陆过
        location.href = `/account/sign-in?app=${app}`;
        throw new Error('unauthorized');
    }

    localStorage.setItem('idToken', tempToken);
    return tempToken;
};